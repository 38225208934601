import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material";

interface Props {
    text: string;
    open: boolean;
    setOpen: (open: any) => void;
    func: () => void;
    funcSec?: (e: any) => void;
    textField?: boolean;
    value?: any;
    setValue?: (value: any) => void;
    error?: boolean;
    title?: string;
}
export const DialogConfirm = ({
    text,
    open,
    setOpen,
    func,
    funcSec,
    textField = false,
    value,
    setValue,
    error,
    title,
}: Props) => {
    return (
        <Dialog
            sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            onBackdropClick={() => setOpen((prev: boolean) => !prev)}
        >
            <DialogTitle>{title ? title : "Are you sure?"}</DialogTitle>
            <DialogContent dividers>
                <Typography>{text}</Typography>
                {textField && setValue && (
                    <TextField
                        sx={{ mt: 2 }}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        error={error}
                    />
                )}
            </DialogContent>
            <DialogActions>
                {funcSec && (
                    <Button autoFocus onClick={(event: any) => funcSec(event)}>
                        Resend code
                    </Button>
                )}
                <Button
                    autoFocus
                    onClick={() => setOpen((prev: boolean) => !prev)}
                >
                    Cancel
                </Button>
                <Button onClick={func}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};
