import {
    FormControl,
    InputLabel,
    MenuItem,
} from "@mui/material";

import Select, { SelectChangeEvent } from '@mui/material/Select';
import {memo} from "react";

import { useTranslation } from "react-i18next";
import "./AlarmProfile.css";

interface Props {
    profileList?: any [];
    profileDefs: {};
    selectedProfile: string;
    setProfileDef: (p:any) => void;
    setProfileSelect: (p:any) => void;
    title: string;
}


export const AlarmProfileSelect = ({profileDefs, setProfileDef, setProfileSelect, selectedProfile, title} : Props) => {
    //console.log ("AlarmProfileSelect", profileDefs)
    const alarmProfileDefs:{[key:string]: {
        headline:string;
    };} = profileDefs;

    const plist = []

    for (var key in alarmProfileDefs) {
        if (alarmProfileDefs.hasOwnProperty (key)) {
            plist.push ([key, alarmProfileDefs[key].headline])
        }
    }

    const alarmProfiles = plist;

    const lookup_alarmProfileKey = (value:string) => {
        for (var i = 0; i < alarmProfiles.length; i++) {
            const ap = alarmProfiles[i];
            if (value === ap[1]) { return ap[0] }
        }
        return "unknown" // default
    }
    const lookup_alarmProfileDef = (value:string) => {
        return alarmProfileDefs[value];
    }

    const { t } = useTranslation();

    const handleAlarmProfileChange = (event: SelectChangeEvent) => {
        setProfileSelect(event.target.value);
        const key = lookup_alarmProfileKey (event.target.value)
        const profileDef = lookup_alarmProfileDef (key)
        setProfileDef (profileDef)
    };
    const show=true;
    return (
        <div>

            {show && <FormControl fullWidth={true} >
                <InputLabel sx={{ color: "text.primary" }}>
                    { t(title) }
                </InputLabel>

                <Select
                    value={selectedProfile}
                    label={ selectedProfile }
                    onChange={handleAlarmProfileChange}
                >
                    {plist.map ((ap) => {
                        return (<MenuItem key = { ap[0] } value={ ap[1] }> { ap[1] } </MenuItem>)
                    })}
                </Select>
            </FormControl>}

        </div>

    )
}

export const AlarmProfileSelectMemo = memo (AlarmProfileSelect);
