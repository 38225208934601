import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import EventTab from './EventTab';

import { Event } from "../pages/WaveMonitor"

interface Props {
    alarmList: Event[],
    eventList: Event[],
    ecgList: Event[],
    onEventSelected: (event: Event) => void,
    style?: React.CSSProperties; // add this line

}

export const EventTable: React.FC<Props> = ({ alarmList, eventList, ecgList, onEventSelected }) => {
  const [tab, setTab] = useState<string>('events');

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '100%'  }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
          {/* <Tab label="Alarms" value="alarms" /> */}
          <Tab label="Events" value="events" />
            <Tab label="ECG" value="ecg" />
          </Tabs>
        </Box>
        {/*
        <TabPanel value="alarms">
          <EventTab events={alarmList} onEventSelected={onEventSelected} />
        </TabPanel>
  */}
        <TabPanel value="events">
          <EventTab events={eventList} onEventSelected={onEventSelected} />
        </TabPanel>
        <TabPanel value="ecg">
          <EventTab events={ecgList} onEventSelected={onEventSelected} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default EventTable;
