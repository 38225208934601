import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { languages } from "../../utils/data/Languages";
import { GetCurrentLanguage } from "../../utils/functions/GetCurrentLanguage";

export const SelectLanguage = () => {
    const handleChange = (event: SelectChangeEvent) => {
        localStorage.setItem("i18next", JSON.stringify(event.target.value));
        i18next.changeLanguage(event.target.value);
    };

    const { t } = useTranslation();
    return (
        <FormControl>
            <InputLabel
                id="demo-simple-select-label"
                sx={{ color: "text.primary" }}
            >
                {t("language")}
            </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={GetCurrentLanguage()!.code}
                label={t("language")}
                onChange={handleChange}
            >
                {languages.map(({ code, name }) => (
                    <MenuItem key={code} value={code}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
