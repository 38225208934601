import {
	Grid,
	Typography,
	useTheme,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tooltip,
	Link,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import SensorsIcon from "@mui/icons-material/Sensors";
import SensorsOffIcon from "@mui/icons-material/Sensors";

//import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { ReactNode, useState } from "react";
import { Device } from "../../interfaces/Device";
import "../../pages/Home/Live/LiveUnit.css";
import { PatientInfo } from "../../pages/Home/Live/LiveUnit";
import { ConditionalLink } from "../../components/ConditionalLink";
import { useTranslation } from "react-i18next";
import { isBrowser } from "react-device-detect";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import { EcgDurationSelector } from "./EcgDurationSelector";
import { useCookies } from "react-cookie";
import DeviceService from "../../services/DeviceService";
import { DialogCaseId } from "../DialogCaseId";
import { DeviceDialog } from "../../pages/Home/Device/DeviceDialog";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

interface Props {
	device: Device;
	selectParams?: ReactNode;
	patientInfo?: PatientInfo;
	useLink?: boolean;
	connectStatus?: string;
	fullWidth?: boolean;
	isVideoOn?: boolean;
	startCamera?: () => void;
	alarmProp?: any;
}

export const LiveUnitHeader = ({
	device,
	selectParams,
	patientInfo,
	useLink = true,
	connectStatus,
	fullWidth = false,
	isVideoOn,
	startCamera,
	alarmProp,
}: Props) => {
	//console.log ("Drawing: LiveUnitHeader", patientInfo);
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [ecgOpen, setEcgOpen] = useState(false);
	const [ecgDuration, setEcgDuration] = useState(10);
	const [cookies, ,] = useCookies(["access_token"]);
	const [, setOpenExportSnackbar] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [showDialog, setShowDialog] = useState(false);

	const { t } = useTranslation();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (
		event: React.SyntheticEvent<unknown>,
		reason?: string
	) => {
		if (reason !== "backdropClick") {
			setOpen(false);
		}
	};

	const handleEcgDurationChange = (val: number) => {
		setEcgDuration(val);
	};

	const handleEcgRecording = () => {
		setEcgOpen(true);
		console.log("Record ECG");
	};

	const handleEcgClose = (
		event: React.SyntheticEvent<unknown>,
		reason?: string
	) => {
		if (reason !== "backdropClick") {
			setEcgOpen(false);
		}
	};

	const handleCSVExport = () => {
		handleFileExport("csv");
	};

	const handlePDFExport = () => {
		handleFileExport("pdf");
	};

	const handleJSONExport = () => {
		handleFileExport("json");
	};

	const handlePNGxport = () => {
		handleFileExport("png");
	};

	const handleFileExport = (export_type: string) => {
		console.log("ECG recording: ", export_type);

		var max_time = new Date();
		max_time = new Date(max_time.getTime() - 2000); // Adjust for two seconds ago.
		const min_time = new Date(max_time.getTime() - ecgDuration * 1000);

		const tzoffset = new Date(max_time).getTimezoneOffset();
		const data = {
			device_id: device.device_id,
			device_type: device.device_type,
			device_model: device.device_model,
			patientinfo: patientInfo, //dataPacket.patientInfo,
			start_time: min_time.toISOString(),
			end_time: max_time.toISOString(),
			tzoffset: tzoffset,
			case_id: device.case_id,
			file_type: export_type,
		};

		setOpenExportSnackbar(true);
		console.log("Export ECG data:", data);
		DeviceService.exportEcg(data, cookies.access_token).then((res: any) => {
			var fileOfBlob = new File([res.data], "chart.tmp");

			const fileURL = window.URL.createObjectURL(fileOfBlob);
			// Setting various property values
			let alink = document.createElement("a");
			alink.href = fileURL;

			const d = new Date();
			const mm = d.getMonth() < 10 ? "0" + d.getMonth() : d.getMonth();
			const dd = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
			const HH = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
			const MM =
				d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();

			const ftime =
				d.getFullYear() + "-" + mm + "-" + dd + "_" + HH + "." + MM;

			alink.download =
				device.device_id + "_ECG_" + ftime + "." + export_type;
			alink.click();
			setOpenExportSnackbar(false);
		});

		setEcgOpen(false);
	};

	const newfolderTip: string = t("journal_chart");
	const recordEcgTip: string = t("record_ecg");
	const monitorLink: string = "/wavemonitor/" + device.device_id;
	const careSummaryLink: string = "/caresummary/" + device.device_id;
	const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";
	//const gridSize: GridSize | undefined = isBrowser ? 3 : undefined;

	return (
		<>
			<Grid
				id="liveUnitHeader"
				container
				direction="row"
				justifyContent="space-between"
				className="monitorHeader"
				px={1}
				pt={1}
			>
				{/* Device Identifier */}
				<Grid
					item
					xs={6}
					sm={fullWidth ? 4 : 5}
					md={isPharlap ? 4 : 3}
					container
					direction="row"
				>
					{connectStatus && (
						<>
							{connectStatus === "connected" ? (
								<SensorsIcon
									style={{ fontSize: 25 }}
									className="connectionOK"
								/>
							) : (
								<SensorsOffIcon
									style={{ fontSize: 25 }}
									className="connectionFail"
								/>
							)}
						</>
					)}
					<ConditionalLink show={useLink} to={monitorLink}>
						<Typography
							color={theme.palette.common.white}
							textAlign="center"
							ml={1}
						>
							{device.nickname
								? device.nickname
								: device.device_id}
						</Typography>
					</ConditionalLink>
				</Grid>

				{/* Patient Identifier */}
				{!isPharlap && (
					<Grid item xs={5} sm={fullWidth ? 2 : 3} md={2}>
						<Typography
							color={theme.palette.common.white}
							textAlign="center"
						>
							{patientInfo?.first_name
								? patientInfo.first_name
								: "-"}
							{patientInfo?.last_name
								? patientInfo.last_name
								: "-"}
						</Typography>
					</Grid>
				)}

				<Grid item xs={2} sm={fullWidth ? 2 : 3} md={isPharlap ? 3 : 1}>
					{isPharlap && isBrowser && (
						<Grid container alignSelf="center">
							<Button
								size={isBrowser ? "medium" : "small"}
								variant="text"
								sx={{
									bottom: 4,
									color: device.case_id
										? "white"
										: "lightgreen",
								}}
								onClick={() => setOpenDialog(true)}
							>
								{device.case_id
									? device.case_id
									: t("add_pet_name")}
							</Button>
						</Grid>
					)}
					{!isPharlap && (
						<Typography
							color={theme.palette.common.white}
							textAlign="center"
						>
							{`${patientInfo?.age ? patientInfo.age : "- /"}
							${patientInfo?.gender ? "/" + patientInfo.gender : "-"}`}
						</Typography>
					)}
				</Grid>

				{!isPharlap && (
					<Grid item xs={4} sm={fullWidth ? 2 : 6} md={2}>
						<Typography
							color={theme.palette.common.white}
							textAlign="center"
						>
							{t("bed")} : {patientInfo?.bed_no}
						</Typography>
					</Grid>
				)}

				<Grid
					container
					item
					direction="row"
					xs={4}
					md={4}
					justifyContent="space-between"
				>
					{/* Settings */}
					{isPharlap && startCamera && (
						<Grid
							container
							item
							xs={isPharlap ? 2 : 4}
							justifyContent="center"
							onClick={startCamera}
							sx={{
								cursor: "pointer",
								color: theme.palette.common.white,
							}}
						>
							{isVideoOn ? <VideocamOffIcon /> : <VideocamIcon />}
						</Grid>
					)}
					<Grid
						container
						item
						xs={isPharlap ? 2 : 3}
						justifyContent="center"
					>
						<Tooltip title={newfolderTip}>
							<Link target="_blank" href={careSummaryLink}>
								<QueryStatsIcon
									style={{
										cursor: "pointer",
										color: "lightgreen",
										fontSize: 22,
									}}
								/>
							</Link>
						</Tooltip>
					</Grid>
					<Grid
						container
						item
						xs={isPharlap ? 2 : 3}
						justifyContent="center"
					>
						<Tooltip title={recordEcgTip}>
							<MonitorHeartIcon
								style={{
									cursor: "pointer",
									color: "lightgreen",
									fontSize: 22,
								}}
								onClick={handleEcgRecording}
							/>
						</Tooltip>
					</Grid>
					<Grid
						container
						item
						xs={isPharlap ? 2 : 3}
						justifyContent="center"
					>
						{selectParams && isBrowser && (
							<SettingsIcon
								style={{
									cursor: "pointer",
									color: "white",
									fontSize: 22,
								}}
								onClick={handleClickOpen}
							/>
						)}
					</Grid>
					<Grid
						container
						item
						xs={isPharlap ? 2 : 3}
						justifyContent="center"
					>
						{selectParams && isBrowser && (
							<DisplaySettingsIcon
								style={{
									cursor: "pointer",
									color: "white",
									fontSize: 22,
								}}
								onClick={() => setShowDialog(true)}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>

			{/* Waveform Selector Dialog*/}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle> {t("select_waveforms")} </DialogTitle>
				<DialogContent>{selectParams}</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}> {t("close")}</Button>
				</DialogActions>
			</Dialog>

			{/* ECG Recording Dialog*/}
			<Dialog open={ecgOpen} onClose={handleEcgClose} maxWidth="md">
				<DialogTitle> {t("record_ecg")} </DialogTitle>
				<DialogContent>
					<Grid container item pt={3} mb={1}>
						<Grid container direction="row" columnSpacing={1}>
							<Grid item>
								<EcgDurationSelector
									sliderWidth={300}
									defaultval={10}
									setParmInterval={setEcgDuration}
									handleChange={handleEcgDurationChange}
									title={t("set_ecg_recording_duration")}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container direction="row">
						<Button
							variant="contained"
							onClick={handlePDFExport}
							style={{ margin: 5 }}
						>
							{t("export_to_pdf")}
						</Button>
						<Button
							variant="contained"
							disabled
							onClick={handleCSVExport}
							style={{ margin: 5 }}
						>
							{t("export_to_csv")}
						</Button>
						<Button
							variant="contained"
							disabled
							onClick={handleJSONExport}
							style={{ margin: 5 }}
						>
							{t("export_to_json")}
						</Button>
						<Button
							variant="contained"
							disabled
							onClick={handlePNGxport}
							style={{ margin: 5 }}
						>
							{t("export_to_png")}
						</Button>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleEcgClose}> {t("close")}</Button>
				</DialogActions>
			</Dialog>
			<DialogCaseId
				device={device}
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
			/>
			{ alarmProp && <DeviceDialog
				device={device}
				openState={showDialog}
				setOpenState={setShowDialog}
				alarmProp={alarmProp}
			/>
			}
		</>
	);
};
