import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	TextField,
	DialogActions,
	Button,
	useTheme,
} from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Device } from "../interfaces/Device";
import DeviceService from "../services/DeviceService";

interface Props {
	device: Device;
	openDialog: boolean;
	setOpenDialog: (open: boolean) => void;
}

export const DialogCaseId = ({ device, openDialog, setOpenDialog }: Props) => {
	const [caseID, setCaseID] = useState(device.case_id);

	const [cookies] = useCookies(["access_token"]);
	const { t } = useTranslation();

	const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";

	const caseIDCancel = () => {
		setOpenDialog(false);
	};

	const theme = useTheme();
	const caseIDSave = () => {
		device.case_id = caseID;
		DeviceService.editDevice(
			device,
			cookies.access_token,
			device.device_id
		).then((res: any) => {
			//console.log ("Result:", res)
			setOpenDialog(false);
			if (res.status === 200) {
				//console.log ("Success")
			} else {
				console.log("Error in updating device:", device);
			}
		});
	};
	return (
		<Dialog open={openDialog}>
			<DialogTitle>
				{isPharlap ? t("change_pet_name") : t("set_case_id")}{" "}
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item xs={12} m={2}>
						<TextField
							InputLabelProps={{ shrink: true }}
							value={caseID}
							id="nickname"
							onChange={(e) => setCaseID(e.target.value)}
							sx={{
								input: {
									color: theme.palette.text.primary,
									background:
										theme.palette.background.default,
								},
								label: {
									color: theme.palette.text.primary,
								},
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button onClick={caseIDCancel}> {t("cancel")}</Button>
				<Button onClick={caseIDSave}> {t("save")}</Button>
			</DialogActions>
		</Dialog>
	);
};
