import {
	Grid,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Device } from "../../interfaces/Device";
import DeviceService from "../../services/DeviceService";
import { useCookies } from "react-cookie";

const scoringColors = {
	mild: "#DAEEF5",
	minor: "#FFFF0055",
	major: "#F39C1255",
	critical: "#FF000055",
	severe: "#FF000055",
	moderate: "#F39C1255",
};

interface ParmProps {
	device: Device;
	ACVPU_val: string;
	RespDistress_val: string;
}

export const ManualParmInput = ({
	device,
	ACVPU_val,
	RespDistress_val,
}: ParmProps) => {
	//console.log ("ManualParmInput:", ACVPU_val)
	const { t } = useTranslation();
	const [showManualInput, setShowManualInput] = useState(false);
	const [ACVPU, setACVPU] = useState(ACVPU_val);
	const [respDistress, setRespDistress] = useState(RespDistress_val);
	const [cookies, ,] = useCookies(["access_token"]);

	useEffect(() => {
		setACVPU(ACVPU_val);
	}, [ACVPU_val]);
	useEffect(() => {
		setRespDistress(RespDistress_val);
	}, [RespDistress_val]);

	const manualInputCancel = () => {
		console.log("manualInputCancel");
		setACVPU(ACVPU_val);
		setRespDistress(RespDistress_val);
		setShowManualInput(false);
	};

	/*
	 * Save the parameters
	 */
	const manualInputSave = () => {
		//console.log ("manualInputSave", ACVPU, ACVPU_val, respDistress, RespDistress_val)

		var saveparms: { [id: string]: string } = {};
		if (ACVPU !== ACVPU_val) {
			saveparms.ACVPU = ACVPU;
		}

		if (respDistress !== RespDistress_val) {
			saveparms.RespDistress = respDistress;
		}

		DeviceService.saveManualParms(
			device.device_id,
			saveparms,
			cookies.access_token
		)
			.then((res: any) => {
				console.log("Success!");
			})
			.catch((err: any) => {
				console.log(err);
			});
		setShowManualInput(false);
	};

	const handleManualInputShow = () => {
		//console.log ("handleManualInput")
		setShowManualInput(true);
	};

	const handleAVPU = (event: any, newValue: string) => {
		//console.log ("handleAVPU", event, newValue)
		setACVPU(newValue);
	};

	const handleRespDistress = (event: any, newValue: string) => {
		//console.log ("handleRespDistress", event, newValue)
		setRespDistress(newValue);
	};

	const clearBoth = () => {
		setACVPU("0");
		setRespDistress("0");
	};

	const showSaveButton =
		ACVPU !== ACVPU_val || respDistress !== RespDistress_val;
	const showClearBoth = ACVPU !== "0" || respDistress !== "0";
	return (
		<>
			<Dialog open={showManualInput} maxWidth={"sm"} fullWidth={true}>
				<DialogTitle> {t("set_manual_input")} </DialogTitle>
				<DialogContent>
					<Grid container direction="row">
						<Grid item xs={4} m={2}>
							<Typography variant="h6" component="h2">
								{t("neurological")}
							</Typography>
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								value={ACVPU}
								onChange={handleAVPU}
							>
								<FormControlLabel
									value="0"
									control={<Radio sx={{ color: "black" }} />}
									label="A - Alert"
									sx={{
										border: "0.5px solid grey",
										backgroundColor: "white",
									}}
								/>
								<FormControlLabel
									value="1"
									control={<Radio sx={{ color: "black" }} />}
									label="C - Confusion"
									sx={{
										border: "0.5px solid grey",
										backgroundColor: scoringColors.minor,
									}}
								/>
								<FormControlLabel
									value="2"
									control={<Radio sx={{ color: "black" }} />}
									label="V - Voice"
									sx={{
										border: "0.5px solid grey",
										backgroundColor: scoringColors.major,
									}}
								/>
								<FormControlLabel
									value="3"
									control={<Radio sx={{ color: "black" }} />}
									label="P - Pain"
									sx={{
										border: "0.5px solid grey",
										backgroundColor: scoringColors.critical,
									}}
								/>
								<FormControlLabel
									value="4"
									control={<Radio sx={{ color: "black" }} />}
									label="U - Unresponsive"
									sx={{
										border: "0.5px solid grey",
										backgroundColor: scoringColors.critical,
									}}
								/>
							</RadioGroup>
						</Grid>{" "}
						{/* AVPU */}
						<Grid item xs={6} m={2}>
							<Typography variant="h6" component="h2">
								{t("respiratory_distress")}
							</Typography>
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								value={respDistress}
								onChange={handleRespDistress}
							>
								<FormControlLabel
									value="0"
									control={<Radio sx={{ color: "black" }} />}
									label={t("normal")}
									sx={{
										border: "0.5px solid grey",
										backgroundColor: "white",
									}}
								/>
								<FormControlLabel
									value="1"
									control={<Radio sx={{ color: "black" }} />}
									label={t("mild")}
									sx={{
										border: "0.5px solid grey",
										backgroundColor: scoringColors.minor,
									}}
								/>
								<FormControlLabel
									value="2"
									control={<Radio sx={{ color: "black" }} />}
									label={t("moderate")}
									sx={{
										border: "0.5px solid grey",
										backgroundColor: scoringColors.major,
										paddingRight: 3,
									}}
								/>
								<FormControlLabel
									value="3"
									control={<Radio sx={{ color: "black" }} />}
									label={t("severe")}
									sx={{
										border: "0.5px solid grey",
										backgroundColor: scoringColors.critical,
									}}
								/>
							</RadioGroup>
						</Grid>{" "}
						{/* Respiratory Distress */}
					</Grid>{" "}
					{/* Container */}
				</DialogContent>

				<DialogActions>
					<div style={{ flex: "1 0 0" }}>
						{showClearBoth && (
							<Button
								onClick={clearBoth}
								style={{ float: "left" }}
							>
								{" "}
								{t("clear_both")}{" "}
							</Button>
						)}
						{showSaveButton && (
							<Button
								variant="contained"
								onClick={manualInputSave}
								style={{ float: "right" }}
							>
								{" "}
								{t("save")}
							</Button>
						)}
						<Button
							onClick={manualInputCancel}
							style={{ float: "right" }}
						>
							{" "}
							{t("cancel")}
						</Button>
					</div>
				</DialogActions>
			</Dialog>{" "}
			{/* Manual Parameter Input dialog */}
			<Button variant="contained" onClick={handleManualInputShow}>
				{t("input_manual_parameters")}
			</Button>
		</>
	);
};
