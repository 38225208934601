import { Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { AlarmsContext } from "../context/AlarmsContext";
import { Alarm } from "../interfaces/Alarm";
import { useTranslation } from "react-i18next";

export const useAlarmMessage = () => {
    const alarmsCtx = useContext(AlarmsContext);
	const { t } = useTranslation();

    const ToastContent = (
        severity: string,
        message: string,
        device: string,
        textcolor: string
    ) => {
        return (
            <>
            <Grid style={{ }}>
                    <Typography variant="h6" style={{}}>{ severity==="Clear" ? t("alarm_cleared") : t("alarm_notification")}</Typography>
                </Grid>
                <Grid style={{ }}>
                    <Typography sx={{ fontWeight: "bold", color:textcolor }}>
                        {severity}
                    </Typography>
                    <Typography>{message}</Typography>
                    <Typography>{device}</Typography>
                </Grid>
            </>
        );
    };

    useEffect(() => {
        if (alarmsCtx?.alarms && alarmsCtx?.alarms?.length > 0) {
            alarmsCtx?.alarms?.forEach((alarm: Alarm) => {
                //console.log (alarm)
                var almColor = '#80BD40'
                if (alarm.severity === 'Minor') almColor = '#BDB652';
                if (alarm.severity === 'Major') almColor = '#FF9933';
                if (alarm.severity === 'Critical') almColor = '#FF0000';
                toast(
                    ToastContent(
                        alarm.severity,
                        alarm.error_message,
                        alarm.device_id,
                        almColor
                    ),
                    {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        style: { width: 500, backgroundColor: '#FFFFFF'}
                    }
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alarmsCtx?.alarms]);
};
