import * as CONSTANTS from "../utils/constants/constants";
const axios = require("axios").default;

class UtilsService {
	getAIModels() {
		return CONSTANTS.HOST + "api/utils/ai-models/";
	}
	getPhysicans() {
		return CONSTANTS.HOST + "api/utils/physicians/";
	}

	assignPhysician(
		physician_id: string,
		patient_id: string,
		access_token: string
	) {
		return axios
			.patch(
				CONSTANTS.HOST +
					"api/utils/physicians/" +
					physician_id +
					"/patients/" +
					patient_id +
					"/assign-physician/",
				{ physician_id, patient_id },
				{
					headers: { authorization: "Bearer " + access_token },
				}
			)
			.then((response: any) => response)
			.catch((err: any) => {
				console.log(err);
				return err.data;
			});
	}
	unassignPhysician(
		physician_id: string,
		patient_id: string,
		access_token: string
	) {
		return axios
			.patch(
				CONSTANTS.HOST +
					"api/utils/physicians/" +
					physician_id +
					"/patients/" +
					patient_id +
					"/unassign-physician/",
				{ physician_id, patient_id },
				{
					headers: { authorization: "Bearer " + access_token },
				}
			)
			.then((response: any) => response)
			.catch((err: any) => {
				console.log(err);
				return err.data;
			});
	}
	getSystemType() {
		return CONSTANTS.HOST + "api/utils/server/type";
	}
}

export default new UtilsService();
