import { Fragment } from "react";
import { CustomRow } from "../../components/CustomRow";
import DeviceMonitor from "../../components/device/DeviceMonitor";
import { Device } from "../../interfaces/Device";
import { ReactNode } from "react";
import { Typography } from "@mui/material";
import {parmMap} from "../../pages/Home/LiveDashboard"

interface Props {
    devicelist: Device[];
    setDevices: (devices: Device[]) => void;
    children: ReactNode;
}

export const StandardView = ({ devicelist, setDevices, children }: Props) => {
    return (
        <>
        { children }
        { devicelist?.length > 0 ? <CustomRow spacingToRow noSpaceBetween>
            {devicelist?.map((device: Device) => {
                return (
                    <Fragment key={device.device_id}>
                        {device.device_id && (
                            <DeviceMonitor
                                device={device}
                                setDevices={setDevices}
                                availableParams={parmMap[device.device_model].parms}
                            />
                        )}
                    </Fragment>
                );
            })}
        </CustomRow> : <Typography>No devices to show</Typography>}
        
        </>
    );
};
