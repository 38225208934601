//import {  useContext } from "react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { useAlarmMessage } from "../../hooks/useAlarmMessage";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import CustomTabs from "../tabs/CustomTabs";
import { TrendDashboard } from "../../pages/Home/TrendDashboard";
import { LiveDashboard } from "../../pages/Home/LiveDashboard";
import { NEWSTable } from "../../pages/Home/NEWS/NEWSTable";
import DeviceService from "../../services/DeviceService";
import VICUService from "../../services/VICUService";
import { StandardView } from "../../pages/Home/StandardView";
import { MapView } from "../../pages/Home/MapView/MapView";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
//import { UserContext } from "../../context/UserContext";
import { useState, useEffect } from "react";
import { Device } from "../../interfaces/Device";
import { Grid, Button } from "@mui/material";

import { VICU } from "../../interfaces/VICU";
import UtilsService from "../../services/UtilsService";

export const CMSDashboard = () => {
	// console.log ("CMSDashboard renders")
	const { t } = useTranslation();
	const [cookies, ,] = useCookies(["access_token"]);
	const [selectedVICU, setSelectedVICU] = useState<VICU | null>(null);
	const [deviceList, setDeviceList] = useState<Device[]>([]);
	var token = cookies.access_token; // userCtx.user.token;

	// Retrieve all devices for this organization the when the CMSDashboard renders.
	// Should only happen once, as this is the top-level component.
	const {
		data: devices,
		setData: setDevices,
		loading,
		error,
	} = useFetch({
		url: DeviceService.getDevices(),
		access_token: token,
	});

	// Fetch all VICUs
	const {
		data: vicu_list,
		loading: viculoading,
		error: vicuerror,
	} = useFetch({
		url: VICUService.getAllVICU(),
		access_token: cookies.access_token,
	});

	const selectDevices = (deviceList: Device[], vicu_id: string) => {
		if (deviceList === null) {
			return [];
		}
		const list: Device[] = [];
		for (var i = 0; i < deviceList.length; i++) {
			const d = deviceList[i];
			if (d.VICU_assignment === vicu_id) {
				list.push(d);
			}
		}
		return list;
	}; // selectDevices

	// INIT CODE
	useEffect(() => {
		if (vicu_list === null || devices === null) {
			return;
		}

		if (selectedVICU === null) {
			if (vicu_list.length > 0) {
				setSelectedVICU(vicu_list[0]);
			}
		}
		const search_vicu: string =
			selectedVICU === null
				? vicu_list.length === 0
					? "None"
					: vicu_list[0].vicu_id
				: selectedVICU.vicu_id;

		setDeviceList(selectDevices(devices, search_vicu));
	}, [devices, selectedVICU, vicu_list]);

	const {
		data: systemType,
		loading: systemTypeLoading,
		error: systemTypeError,
	} = useFetch({
		url: UtilsService.getSystemType(),
		access_token: token,
	});

	useEffect(() => {
		if (systemType) {
			localStorage.setItem("SYSTEM_TYPE", systemType["system_type"]);
		}
	}, [systemType]);

	useAlarmMessage();

	if (loading || viculoading || systemTypeLoading) {
		return <Loading />;
	}

	if (error || vicuerror || systemTypeError) {
		return <ServerProblem />;
	}

	console.log("Device list:", devices);
	// console.log ("VICU list:", vicu_list)
	if (systemType && systemType["system_type"] === "PHARLAP") {
		return <LiveDashboard devicelist={devices} />;
	}

	return (
		<div>
			<CustomTabs
				tabNames={[
					t("live_dashboard"),
					t("news_dashboard"),
					t("trend_dashboard"),
					t("standard_dashboard"),
					t("mapview"),
				]}
				tabComponents={[
					<LiveDashboard devicelist={deviceList}>
						<VicuTabBar
							vicuList={vicu_list}
							activateVicu={setSelectedVICU}
							activeVicu={selectedVICU}
						/>
					</LiveDashboard>,
					<NEWSTable devicelist={deviceList}>
						<VicuTabBar
							vicuList={vicu_list}
							activateVicu={setSelectedVICU}
							activeVicu={selectedVICU}
						/>
					</NEWSTable>,
					<TrendDashboard devices={deviceList}>
						<VicuTabBar
							vicuList={vicu_list}
							activateVicu={setSelectedVICU}
							activeVicu={selectedVICU}
						/>
					</TrendDashboard>,
					<StandardView
						devicelist={deviceList}
						setDevices={setDevices}
					>
						<VicuTabBar
							vicuList={vicu_list}
							activateVicu={setSelectedVICU}
							activeVicu={selectedVICU}
						/>
					</StandardView>,
					<MapView devices={deviceList} setDevices={setDevices}>
						<VicuTabBar
							vicuList={vicu_list}
							activateVicu={setSelectedVICU}
							activeVicu={selectedVICU}
						/>
					</MapView>,
				]}
			/>
		</div>
	);
};

interface VicuTabBarProps {
	vicuList: VICU[];
	activateVicu: any;
	activeVicu?: VICU | null;
}
export const VicuTabBar = ({
	vicuList,
	activateVicu,
	activeVicu,
}: VicuTabBarProps) => {
	return (
		<>
			{/* VICU list */}
			<Grid container direction="row" sx={{ paddingBottom: 1 }}>
				{vicuList?.map((vicu: VICU) => (
					<VicuTab
						key={vicu.vicu_id}
						vicu={vicu}
						activateVicu={activateVicu}
						active={activeVicu === vicu}
					/>
				))}
			</Grid>
		</>
	);
};

interface VicuTabProps {
	vicu: VICU;
	activateVicu: any;
	active: boolean;
}

export const VicuTab = ({ vicu, activateVicu, active }: VicuTabProps) => {
	const selectVICU = (v: VICU) => {
		activateVicu(v);
	};

	return (
		<div>
			<Button
				variant={active ? "contained" : "outlined"}
				sx={{ marginRight: 1 }}
				onClick={(event: any) => selectVICU(vicu)}
			>
				{vicu.vicu_name}
			</Button>
		</div>
	);
};
