import moment from "moment";
import * as CONSTANTS from "../utils/constants/constants";

class TrendService {
    getTrend48Hours() {
        var timedifference = new Date().getTimezoneOffset();
        const startTime = moment()
            .subtract(2, "days")
            .add(-timedifference, "minutes")
            .utc()
            .format();

            return (
                CONSTANTS.HOST +
                "api/devices/REPLACE/data/trend/range/all/duration/172800/start_time/" + startTime + "/"
            );
        }
}
export default new TrendService();

/*
        return (
            CONSTANTS.HOST +
            "api/devices/REPLACE/data/trend/range/all/duration/172800/start_time/" +
            startTime +
            "/"
        );

*/