import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Patient } from "../interfaces/Patient";

interface PatientCtx {
    patients: Patient[] | null;
    setPatients: Dispatch<SetStateAction<Patient[] | null>>;
    patient: Patient | null;
    setPatient: Dispatch<SetStateAction<Patient | null>>;
}

export const PatientsContext = createContext<PatientCtx | null>(null);

export default function PatientsProvider({ children }: any) {
    const [patients, setPatients] = useState<Patient[] | null>(null);
    const [patient, setPatient] = useState<Patient | null>(null);

    const patientsContext = { patients, setPatients, patient, setPatient };

    return (
        <PatientsContext.Provider value={patientsContext}>
            {children}
        </PatientsContext.Provider>
    );
}
