import { Language } from "../../interfaces/Language";

export const languages: Language[] = [
    {
        code: "ar",
        name: "Arabic",
        country_code: "ar",
    },{
        code: "en",
        name: "English",
        country_code: "gb",
    },
    {
        code: "fr",
        name: "Français",
        country_code: "fr",
    },
    {
        code: "no",
        name: "Norsk",
        country_code: "no",
    },
    {
        code: "ro",
        name: "Română",
        country_code: "ro",
    },

];
