const UpdateVitals = (
    event?: any,
    setVitals?: any,
    setNews?: any,
    setAlarms?: any,
    setPatientInfo? : any
) => {
    try {
        if (JSON.parse(event.data).message.alarms) {
            const data = JSON.parse(event.data);

            const tempAlarms = [];
            for (let i = 0; i < data.message.alarms.length; i++) {
                if (
                    JSON.parse(event.data).message.alarms[i].severity !==
                    "Clear"
                ) {
                    //console.log(data.message);
                    const alarm = {
                        ...JSON.parse(event.data).message.alarms[i],
                        device_id: data.message.device_id,
                    };
                    tempAlarms.push(alarm);
                }
            }
            setAlarms(tempAlarms);
        }
        let ws_data = JSON.parse(event.data).message.parameters;
        let news = JSON.parse(event.data).message["NEWS2"];
        let thresholds = JSON.parse(event.data).message["alarm_status"];
        let patient = JSON.parse(event.data).message["patient"];

        if (
            typeof ws_data === "object" &&
            ws_data !== null &&
            thresholds !== null
        ) {
            // console.log ("ws data:", ws_data)

            const tempIBP1 = ws_data.IBP1_Systole ? {
                systole: ws_data.IBP1_Systole,
                diastole: ws_data.IBP1_Diastole,
                map: ws_data.IBP1_Map,
                active_alarm: (ws_data?.IBP1_Systole?.error_alarm_flag !== "0") || 
                              (ws_data?.IBP1_Diastole?.error_alarm_flag !== "0") ||
                              (ws_data?.IBP1_Map?.error_alarm_flag !== "0")
            } : undefined;

            const tempIBP2 = ws_data.IBP2_Systole ? {
                systole: ws_data.IBP2_Systole,
                diastole: ws_data.IBP2_Diastole,
                map: ws_data.IBP2_Map,
                active_alarm: (ws_data?.IBP2_Systole?.error_alarm_flag !== "0") || 
                              (ws_data?.IBP2_Diastole?.error_alarm_flag !== "0") ||
                              (ws_data?.IBP2_Map?.error_alarm_flag !== "0"),
            } : undefined;

            const tempNIBP = ws_data.NIBP_Systole ? {
                systole: ws_data.NIBP_Systole,
                diastole: ws_data.NIBP_Diastole,
                map: ws_data.NIBP_Map,
                high: thresholds[2].thresholds.hi,
                low: thresholds[2].thresholds.low,
                active_alarm: (ws_data?.NIBP_Systole?.error_alarm_flag !== "0") || 
                              (ws_data?.NIBP_Diastole?.error_alarm_flag !== "0") ||
                              (ws_data?.NIBP_Map?.error_alarm_flag !== "0"),
            } : undefined;

            const tempSpo2 = ws_data.SpO2 ? {
                sp: ws_data.SpO2,
                perf: ws_data.Perf,
                high: thresholds[1].thresholds.hi,
                low: thresholds[1].thresholds.lo,
                active_alarm: ws_data?.SpO2?.error_alarm_flag !== "0",
            } : undefined;

            const tempRR = ws_data.Resp ? {
                rr: ws_data.Resp,
                high: thresholds[0].thresholds.hi,
                low: thresholds[0].thresholds.lo,
                active_alarm: ws_data?.Resp?.error_alarm_flag !== "0"
            } : undefined;

            const tempEtCO2 = ws_data.EtCO2 ? {
                co2: ws_data.EtCO2,
                high: thresholds[0].thresholds.hi,
                low: thresholds[0].thresholds.lo,
                active_alarm: ws_data?.EtCO2?.error_alarm_flag !== "0"
            } : undefined;

            const tempAct = ws_data.Act ? {
                act: ws_data.Act,
                high: thresholds[0].thresholds.hi,
                low: thresholds[0].thresholds.lo,
                active_alarm: ws_data?.Act?.error_alarm_flag !== "0"
            } : undefined;

            const tempT = ws_data.Temp1 ? {
                t1: ws_data.Temp1,
                t2: ws_data.Temp2,
                high: thresholds[4].thresholds.hi,
                low: thresholds[4].thresholds.lo,
                active_alarm: (ws_data?.Temp1 && ws_data?.Temp1?.error_alarm_flag !== "0") || (ws_data?.Temp2 && ws_data?.Temp2?.error_alarm_flag !== "0"),
            } : undefined;

            ws_data.HR.high = thresholds[3].hi;
            ws_data.HR.low = thresholds[3].lo;
            ws_data.HR.active_alarm = ws_data?.HR?.error_alarm_flag !== "0";
            
            if (patient && setPatientInfo) {
                setPatientInfo (patient)
            }

            const vital_ret = {
                HR: ws_data.HR ? ws_data.HR : undefined,
                SpO2: tempSpo2,
                IBP1: tempIBP1,
                IBP2: tempIBP2,
                NIBP: tempNIBP,
                Resp: tempRR,
                Temp: tempT,
                EtCO2: tempEtCO2,
                Act: tempAct,
                ACVPU:        ws_data.ACVPU,
                RespDistress: ws_data.RespDistress
            }
            if (setVitals)
                setVitals(vital_ret);

            if (setNews)
                setNews(news);

            return vital_ret
        }
    } catch (e) {
        console.log(e);
    }
    return {
        HR: {}, SpO2: {}, IBP1: {}, IBP2: {}, Resp: {}, Temp: {}, ACVPU: {}, RespDistress: {}
    }
};

export default UpdateVitals;
