import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
    Typography,
} from "@mui/material";

import { Loading } from "../../components/Loading";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VICU } from "../../interfaces/VICU";
import { VicuDialog } from "./VicuDialog";
import { isBrowser } from "react-device-detect";

interface Props {
    viculist: VICU[];
}

export const VicuTable = ({ viculist }: Props) => {
    const [vicu, setVicu] = useState<VICU | null>(null);
    const [showDialog, setShowDialog] = useState(false);

    const { t } = useTranslation();
    const theme = useTheme();
    const fontVariant = isBrowser ? "body2" : "caption";

    if (!viculist) {
        return <Loading />;
    }

    console.log("VICU list:", viculist);
    return (
        <TableContainer component={Paper}>
            {vicu && (
                <VicuDialog
                    openState={showDialog}
                    setOpenState={setShowDialog}
                    targetvicu={vicu}
                />
            )}

            <Table aria-label="simple table">
                <TableHead>
                    <TableRow
                        sx={{
                            border: 0,
                            bgcolor: theme.palette.primary.main,
                            "& th": {
                                color: theme.palette.text.secondary,
                            },
                        }}
                    >
                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("monitor_group")}
                        </TableCell>

                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("vicu_type")}
                        </TableCell>


                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("n_monitors")}
                        </TableCell>
                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("contact")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {viculist?.map((v: VICU, i: number) => (
                        <TableRow
                            onClick={() => {
                                setVicu(v);
                                //vicuRef.current = v;
                                setShowDialog(true);
                            }}
                            key={i}
                            hover
                            sx={{
                                "&:nth-of-type(odd)": {
                                    background: theme.palette.info.dark,
                                },
                                "&:nth-of-type(even)": {
                                    background: theme.palette.info.light,
                                },
                                cursor: "pointer",
                            }}
                        >
                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {v.vicu_name}
                                </Typography>
                            </TableCell>

                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {t(v.vicu_type)}
                                </Typography>
                            </TableCell>


                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {v.n_monitors}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {v.primary_contact}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
