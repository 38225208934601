import { createContext, Dispatch, SetStateAction, useState } from "react";

interface ParametersCtx {
    deviceParameters: DeviceParameters[];
    setSelectedDeviceParameters: Dispatch<SetStateAction<DeviceParameters[]>>;
}

interface DeviceParameters {
    monitor: string;
    params: string[];
}

// @ts-ignore
export const ParametersContext = createContext<ParametersCtx>();

export default function ParametersProvider({ children }: any) {
    const [deviceParameters, setSelectedDeviceParameters] = useState<
        DeviceParameters[]
    >([]);

    const parametersContext = { deviceParameters, setSelectedDeviceParameters };

    return (
        <ParametersContext.Provider value={parametersContext}>
            {children}
        </ParametersContext.Provider>
    );
}
