import * as CONSTANTS from "../utils/constants/constants";

const axios = require("axios").default;

class VICUService {
    getAllVICU() {
        return CONSTANTS.HOST + "api/icus/";
    }

    getOneVICU() {
        return CONSTANTS.HOST + "api/icus/REPLACE/";
    }

    addVICU(data: any, access_token: string) {
        console.log ("addVCU: ", data)
        return axios
            .post(CONSTANTS.HOST + "api/icus/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }

    getVICUPatients() {
        return CONSTANTS.HOST + "api/icus/REPLACE/patients/";
    }
    
    getVICUDevices() {
        return CONSTANTS.HOST + "api/icus/REPLACE/devices/";
    }
    getAvailableDevices () {
        return CONSTANTS.HOST + "api/icus/REPLACE/availabledevices/";
    }

    manageVICUDevices(vicu_id: string, data: any, access_token: string) {
        return axios
            .patch(
                CONSTANTS.HOST + "api/icus/" + vicu_id + "/manage-devices/",
                data,
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    deleteVICU(vicu_id: string, access_token: string) {
        return axios
            .delete(CONSTANTS.HOST + "api/icus/" + vicu_id + "/", {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    editVICU(vicu_id: string, name: string, contact: string, parmGranularity: number, vicu_type: string, access_token: string) {

        const data = {
            vicu_name        : name,
            contact_info     : contact,
            vicu_type        : vicu_type,
            parm_granularity : parmGranularity
        }

        return axios
            .patch(
                CONSTANTS.HOST + "api/icus/" + vicu_id + "/", data, 
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
}

export default new VICUService();
