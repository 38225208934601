import { Grid } from "@mui/material";
import { Fragment, ReactNode } from "react";

interface Props {
    children: ReactNode;
    alignItems?: string;
    spacingToRow?: boolean;
    noSpaceBetween?: boolean;
}

export const CustomRow = ({
    children,
    alignItems,
    spacingToRow,
    noSpaceBetween = false,
}: Props) => {
    return (
        <Grid
            spacing={spacingToRow ? 4 : 0}
            container
            direction="row"
            justifyContent={!noSpaceBetween ? "space-between" : "flex-start"}
            alignItems={alignItems}
        >
            <Fragment>{children}</Fragment>
        </Grid>
    );
};
