import { createContext, Dispatch, SetStateAction, useState } from "react";
import { ChatItemInterface } from "../interfaces/Chat/ChatItemInterface";

interface ChatCtx {
    chat: ChatItemInterface | null;
    setChat: Dispatch<SetStateAction<ChatItemInterface | null>>;
    updateChats: boolean;
    setUpdateChats: any;
}

export const ChatContext = createContext<ChatCtx | null>(null);

export default function ChatProvider({ children }: any) {
    const [chat, setChat] = useState<any | null>(null);
    const [updateChats, setUpdateChats] = useState(false);

    const chatContext = { chat, setChat, updateChats, setUpdateChats };

    return (
        <ChatContext.Provider value={chatContext}>
            {children}
        </ChatContext.Provider>
    );
}
