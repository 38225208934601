
export const PackageVitals = (
    connectStatus: string,
    event?: any
) => {
    try {
        const eventData = JSON.parse (event.data);
        //console.log ("event data:", eventData)
        /*
         * Special case: if it's a trend datagram then handle that here.
         */
        if (eventData.opcode === "device_trend_datagram") {
            return trendVitals (event, connectStatus)
        }
        if (eventData.message.alarms) {

            const tempAlarms = [];
            for (let i = 0; i < eventData.message.alarms.length; i++) {
                if (eventData.message.alarms[i].severity !== "Clear") {
                    //console.log(data.message);
                    const alarm = {
                        ...eventData.message.alarms[i],
                        device_id: eventData.message.device_id,
                    };
                    tempAlarms.push(alarm);
                }
            }
            //setAlarms(tempAlarms);
        } // if Alarms

        let ws_data    = eventData.message.parameters;
        let news       = eventData.message["NEWS2"];
        let thresholds = eventData.message["alarm_status"];
        let patient    = eventData.message["patient"];

        if (
            typeof ws_data === "object" &&
            ws_data !== null &&
            thresholds !== null
        ) {
            const tempIBP1 = Object.keys (ws_data).includes ("IPB1_Systole") ? {
                systole: ws_data.IBP1_Systole,
                diastole: ws_data.IBP1_Diastole,
                map: ws_data.IBP1_Map,
                active_alarm: (ws_data.IBP1_Systole.error_alarm_flag !== "0") || 
                              (ws_data.IBP1_Diastole.error_alarm_flag !== "0") ||
                              (ws_data.IBP1_Map.error_alarm_flag !== "0"),
            } : null;

            const tempIBP2 = Object.keys (ws_data).includes ("IPB2_Systole") ?  {
            
                systole: ws_data.IBP2_Systole,
                diastole: ws_data.IBP2_Diastole,
                map: ws_data.IBP2_Map,
                active_alarm: (ws_data.IBP2_Systole.error_alarm_flag !== "0") || 
                              (ws_data.IBP2_Diastole.error_alarm_flag !== "0") ||
                              (ws_data.IBP2_Map.error_alarm_flag !== "0"),
            } : null;

            const tempNIBP = Object.keys (ws_data).includes ("NIBP_Systole") ?  {
                systole: ws_data.NIBP_Systole,
                diastole: ws_data.NIBP_Diastole,
                map: ws_data.NIBP_Map,
                high: thresholds[2].thresholds.hi,
                low: thresholds[2].thresholds.low,
                active_alarm: (ws_data.NIBP_Systole.error_alarm_flag !== "0") || 
                              (ws_data.NIBP_Diastole.error_alarm_flag !== "0") ||
                              (ws_data.NIBP_Map.error_alarm_flag !== "0"),
            } : null;

            const tempSpo2 = Object.keys (ws_data).includes ("SpO2") ?  {
                sp: ws_data.SpO2,
                perf: ws_data.Perf,
                high: thresholds[1].thresholds.hi,
                low: thresholds[1].thresholds.lo,
                active_alarm: ws_data.SpO2.error_alarm_flag !== "0",
            } : null;

            const tempRR = Object.keys (ws_data).includes ("Resp") ?  {
                rr: ws_data.Resp,
                high: thresholds[0].thresholds.hi,
                low: thresholds[0].thresholds.lo,
                active_alarm: ws_data.Resp.error_alarm_flag !== "0"
            } : null;

            const tempEtCO2 = Object.keys (ws_data).includes ("EtCO2") ?  {
                co2: ws_data.EtCO2,
                high: thresholds[0].thresholds.hi,
                low: thresholds[0].thresholds.lo,
                active_alarm: ws_data.EtCO2.error_alarm_flag !== "0"
            } : null;

            const tempT = Object.keys (ws_data).includes ("Temp1") ?  {
                t1: ws_data.Temp1,
                t2: ws_data.Temp2,
                high: thresholds[4].thresholds.hi,
                low: thresholds[4].thresholds.lo,
                active_alarm: (ws_data.Temp1.error_alarm_flag !== "0") || (ws_data.Temp2.error_alarm_flag !== "0"),
            } : null;

            const tempAct = Object.keys (ws_data).includes ("Act") ?  {
                act: ws_data.Act,
                high: thresholds[4].thresholds.hi,
                low: thresholds[4].thresholds.lo,
                active_alarm: ws_data.Act.error_alarm_flag !== "0",
            } : null;

            ws_data.HR.high = thresholds[3].hi;
            ws_data.HR.low = thresholds[3].lo;
            ws_data.HR.active_alarm = ws_data.HR.error_alarm_flag !== "0";
            //console.log (ws_data.HR)
            const gps = eventData.message.gps_location;
            const ts  = eventData.message.timestamp;

            const vital_ret = {
                HR:            ws_data.HR,
                SpO2:          tempSpo2,
                IBP1:          tempIBP1,
                IBP2:          tempIBP2,
                NIBP:          tempNIBP,
                Resp:          tempRR,
                Temp:          tempT,
                EtCO2:         tempEtCO2,
                ACVPU:         ws_data.ACVPU,
                Act:           tempAct,
                RespDistress:  ws_data.RespDistress,
                news:          news,
                patientInfo:   patient,
                gps:           gps,
                timestamp:     ts,
                connectStatus: connectStatus
            }
            return vital_ret
        }
    } catch (e) {
        console.log(e);
    }

    // Error case
    return {}
    
};


const trendVitals = (msg:any, connectStatus:string) => {
    const data = JSON.parse (msg.data)
    const parms = data.message.data;
    
    const vital_ret = {
        HR:            parms.HR,
        SpO2:          parms.SpO2,
        IBP1:          parms.IBP1_Systole,
        IBP2:          parms.IPB2_Systole,
        NIBP:          parms.NIBP_Systole,
        Resp:          parms.Resp,
        Temp:          parms.Temp1,
        EtCO2:         parms.EtCO2,
        ACVPU:         parms.ACVPU,
        RespDistress:  parms.RespDistress,
        news:          parms.news2,
        Act:           parms.Act,
        gps:           parms.gps_location,
        timestamp:     parms.timestamp,
        connectStatus: connectStatus
    }
    return vital_ret

}