import { useEffect, useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { UserContext } from "../context/UserContext";
import UserService from "../services/UserService";
import IdleTimer from "./IdleTimer";

import * as CONSTANTS from "../utils/constants/constants";

const useMove = () => {
    const [isMoving, setIsMoving] = useState<boolean>(false);

    const handleMouseMove = () => {
        setIsMoving((prev) => !prev);
    };
    return {
        isMoving,
        handleMouseMove,
    };
};

export const useAutoLogout = () => {
    const [cookies, , removeCookie] = useCookies([
        "access_token",
        "refresh_token",
    ]);
    const userCtx = useContext(UserContext);
    const [timeoutValue, setTimeoutValue] = useState(
        userCtx?.user?.autoLogout
            ? parseInt(userCtx.user.autoLogout) * 60
            : CONSTANTS.DEFAULT_AUTOLOGOUT_SECONDS
    );

    const { isMoving, handleMouseMove } = useMove();

    useEffect(() => {
        if (cookies["access_token"] && userCtx?.user?.is_auto_logout_enabled) {
            const timer = new IdleTimer({
                timeout: timeoutValue,
                onTimeout: () => {
                    console.log("UseAutoLogout Timeout!");
                    force_logout();
                },
                onExpired: () => {
                    // do something if expired on load
                    console.log("UseAutoLogout Expired!");
                    force_logout();
                },
            });

            return () => {
                timer.cleanUp();
            };
        }

        // eslint-disable-next-line
    }, [timeoutValue, userCtx?.user, isMoving]);

    const force_logout = () => {
        UserService.blacklistToken(cookies.refresh_token).then(() => {
            userCtx?.setUser(null);
            // localStorage.removeItem("user");
            removeCookie("access_token");
            removeCookie("refresh_token");
        });
    };

    useEffect(() => {
        if (userCtx?.user) {
            setTimeoutValue(
                userCtx?.user?.autoLogout
                    ? parseInt(userCtx?.user?.autoLogout) * 60
                    : CONSTANTS.DEFAULT_AUTOLOGOUT_SECONDS
            );
        }
        // eslint-disable-next-line
    }, [userCtx?.user]);

    return { handleMouseMove };
};
