export interface Organization {
    organization_id   : string;
    organization_name : string;
    admin_email       : string;
    data_resolution   : number;
    hotstore_duration : number;

    ingestion_topic   : string;
    ingestion_server  : string;
    ingestion_key     : string;
    ingestion_group   : string;
    stream_type       : string;

    fleetstatus_topic : string;
    backingstore_host : string;
    backingstore_port : number;
    hotstore_host     : string;
    hotstore_port     : number;
    journalstore_host : string;
    journalstore_port : number;
    db_uid            : string;
    db_pwd            : string;
    ai_execution      : string;
    sentio_type       : string;
    proxy_device_server : string;
    vicu_licenses     : string;
    login_domain      : string;

}

// NB: make sure these harmonize witn back-end definitions in organization.models.py
export const SENTIO_TYPES:{ [key: string] : string} = {
    "standard" : "SentioWeb Standard",
    "active":   "SentioWeb Active"
}

export const SENTIO_AI_YES_NO:{ [key: string] : string} = {
    "Y" : "yes",
    "N" : "no"
}
