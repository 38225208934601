import { useContext, useEffect, useState, useRef } from "react";
//import { AlarmsContext } from "../context/AlarmsContext";
import { Device } from "../interfaces/Device";
import {PackageVitals} from "../utils/functions/PackageVitals";
import * as CONSTANTS from "../utils/constants/constants";
import { UserContext } from "../context/UserContext";

interface Props {
    device: Device;
    setDataPacket: (data:any) => void;
    trendData?: boolean;
}

const useSentioSocket = ({ device, setDataPacket, trendData = false }: Props) => {
    //const alarmsCtx = useContext(AlarmsContext);
    const userCtx = useContext(UserContext);
    const last_datagram_ts = useRef(0);
    const [connectStatus, setConnectStatus] = useState<string>("disconnected");
    const socketConnectStatus = useRef(false);

    const start_monitor_connectivity_check = (isMounted: boolean) => {
        if (isMounted) setTimeout(check_monitor_timeout, 3000, this);
        //console.log ("Start connectivity check ", device.device_id)
    };

    const check_monitor_timeout = (isMounted: boolean) => {
        let timer = setTimeout(check_monitor_timeout, 3000, this);

        let time_now = new Date().getTime();
        let diff = time_now - last_datagram_ts.current;
        //console.log ("check timeout:", device.device_id, last_datagram_ts.current, diff)

        if (diff > CONSTANTS.CONNECTIVITY_TIMEOUT) {
            if (socketConnectStatus.current && isMounted) {
                //console.log ("Disconnected", device.device_id)
                setConnectStatus("disconnected");
                socketConnectStatus.current = false;
            }
        }
        if (!isMounted) clearTimeout(timer);
    };

    const strobe_connectivity = (isMounted: boolean) => {
        //console.log ("Strobe:", device.device_id)
        if (!socketConnectStatus.current && isMounted) {
            //console.log ("Connected:", device.device_id)
            setConnectStatus("connected");
            socketConnectStatus.current = true;
        }
        last_datagram_ts.current = new Date().getTime();
    };


    useEffect(() => {
        let isMounted = true;
        const socket = new WebSocket (CONSTANTS.WS_HOST() + "ws/vicu-socket/" + userCtx?.user?.organization + "/" + device.device_id + "/");

        socket.onmessage = (event) => {
            try {
                if (event.data !== "") {
                    //const ts = JSON.parse(event.data).message.timestamp;
                    //console.log ("--> Device message", ts)
                    //if (isMounted) setTimestamp(ts);

                    strobe_connectivity(isMounted);
                    //sortScores();

                    const vitals_ret = PackageVitals (connectStatus, event);
                    setDataPacket (vitals_ret)


                    const gps = JSON.parse(event.data)?.message.gps_location;
                    if (gps && gps.lat && gps.lng) {
                        if (!isNaN(gps.lat) && !isNaN(gps.lng)) {
                            //setGPSlocation(gps);
                            //console.log ("        set gps location", ts)
                        }
                    }
                } else {
                    //setStaleData(true);
                    //if (isMounted) sortScores();
                }
            } catch (err: any) {
                console.error(err);
            }
        };

        socket.onopen = () => {
            // Send WS message about what trend data we want
            const streamRequest = {
                type: "server-request",
                opcode: trendData ? "stream_trend" : "stream_parameters",
                organization: userCtx?.user?.organization,
                device_id: device.device_id,
            };

            socket.send(JSON.stringify(streamRequest));
            start_monitor_connectivity_check(isMounted);
        };

        return () => {
            console.log("Closing socket");
            socket.close();
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device.device_id]);

};

export default useSentioSocket;
