import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login } from "./pages/Login/Login";

import "./index.css";
import ProtectedRoute from "./components/ProtectedRoute";
import UserProvider from "./context/UserContext";
import ThemeContextProvider from "./context/ThemeContext";
import { CookiesProvider } from "react-cookie";
import { CMSWrapper } from "./components/CMS/CMSWrapper";
import ParametersProvider from "./context/ParametersContext";
import PatientsProvider from "./context/PatientsContext";
import DevicesProvider from "./context/DevicesContext";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { PageNotFound } from "./pages/ReRoute/PageNotFound";
import ChatProvider from "./context/ChatContext";
import AlarmsProvider from "./context/AlarmsContext";
import { ToastContainer } from "react-toastify";
import { CMSDashboard } from "./components/CMS/CMSDashboard";
import { Profile } from "./pages/Profile/Profile";
import { ForgotPassword } from "./pages/Login/ForgotPassword";
import { UserManagement } from "./pages/UserMgmt/UserManagement";
import { DeviceManagement } from "./pages/DeviceManagement/DeviceManagement";
import { VicuManagement } from "./pages/VicuManagement/VicuManagement";
import { SystemManagement } from "./pages/SystemManagement/SystemManagement";
import { ResetPassword } from "./pages/Login/ResetPassword";
import DeviceSocketProvider from "./context/DeviceSocketContext";
import { WaveMonitor } from "./pages/WaveMonitor";
import { CareSummary } from "./pages/CareSummary";
import * as CONSTANTS from "./utils/constants/constants";
import { useState } from "react";
import { User } from "./interfaces/user/User";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

i18next
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: ["en", "fr", "no", "ar", "ro"],
		fallbackLng: "en",
		debug: false,
		// Options for language detector
		detection: {
			order: ["path", "cookie", "htmlTag"],
			caches: ["cookie"],
		},
		// react: { useSuspense: false },
		backend: {
			loadPath: "/assets/locales/{{lng}}/translation.json",
		},
	});

const App = () => {
	console.log("HOST is ", CONSTANTS.HOST);
	console.log("process.env.PUBLIC_URL is ", process.env.PUBLIC_URL);
	const [user] = useState<User | null>(
		// @ts-ignore
		JSON.parse(localStorage.getItem("user")) || null
	);
	console.log("user :", user);

	return (
		<CookiesProvider>
			<ThemeContextProvider>
				<UserProvider>
					<ChatProvider>
						<DevicesProvider>
							<ParametersProvider>
								<AlarmsProvider>
									<PatientsProvider>
										<ToastContainer
											position="top-right"
											autoClose={5000}
											hideProgressBar={false}
											newestOnTop
											closeOnClick
											rtl={false}
											draggable
											pauseOnHover
											pauseOnFocusLoss={false}
										/>
										<DeviceSocketProvider>
											<Router
												basename={
													process.env.PUBLIC_URL + "/"
												}
											>
												<CMSWrapper>
													<Switch>
														<Route
															exact
															path="/login"
															component={Login}
														/>
														<ProtectedRoute
															exact
															path="/profile"
															component={Profile}
														/>
														<ProtectedRoute
															exact
															path="/wavemonitor/:deviceId"
															component={
																WaveMonitor
															}
														/>
														<ProtectedRoute
															exact
															path="/caresummary/:deviceId"
															component={
																CareSummary
															}
														/>
														<ProtectedRoute
															exact
															path="/usermgmt"
															component={
																UserManagement
															}
														/>
														<ProtectedRoute
															exact
															path="/devicemgmt"
															component={
																DeviceManagement
															}
														/>
														<ProtectedRoute
															exact
															path="/vicumgmt"
															component={
																VicuManagement
															}
														/>
														<ProtectedRoute
															exact
															path="/sysmgmt"
															component={
																SystemManagement
															}
														/>

														<Route
															exact
															path="/forgot-password"
															component={
																ForgotPassword
															}
														/>
														<Route
															exact
															path="/password-reset/:token"
															component={
																ResetPassword
															}
														/>

														<ProtectedRoute
															exact
															path="/"
															component={
																CMSDashboard
															}
														/>
														<Route
															path="*"
															component={
																PageNotFound
															}
														/>
													</Switch>
												</CMSWrapper>
											</Router>
										</DeviceSocketProvider>
									</PatientsProvider>
								</AlarmsProvider>
							</ParametersProvider>
						</DevicesProvider>
					</ChatProvider>
				</UserProvider>
			</ThemeContextProvider>
		</CookiesProvider>
	);
};

const loadingMarkup = (
	<div>
		<h3>Loading..</h3>
	</div>
);

ReactDOM.render(
	<Suspense fallback={loadingMarkup}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Suspense>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

/*

import { Waveforms } from "./pages/Waveforms/Waveforms";
import { JournalArchivePatient } from "./pages/JournalArchive/JournalArchivePatient";
import { PatientRegistry } from "./pages/PatientRegistry/PatientRegistry";
import { AddPatient } from "./pages/PatientRegistry/patients/AddPatient";
import { EditPatient } from "./pages/PatientRegistry/patients/EditPatient";
import { AllVICU } from "./pages/VICU/AllVICU";
import { ManageVICU } from "./pages/VICU/ManageVICU";
import { SegmentAnalysis } from "./pages/JournalArchive/Analysis/SegmentAnalysis";
import { DeviceDetails } from "./pages/Home/Device/DeviceDetails";
import { AddDevice } from "./pages/Home/Device/AddDevice";
import { EditDevice } from "./pages/Home/Device/EditDevice";
import { Cohort } from "./pages/PatientRegistry/cohorts/Cohort";
import { EditCohort } from "./pages/PatientRegistry/cohorts/EditCohort";
import { AddCohort } from "./pages/PatientRegistry/cohorts/AddCohort";
import { Staff } from "./pages/Home/Staff/Staff";
import { Journal } from "./pages/JournalArchive/Journal";
import { JournalArchive } from "./pages/JournalArchive/JournalArchive";






                                                    <ProtectedRoute
                                                        exact
                                                        path="/vicu/patient/:patientId/device/:deviceId/wave"
                                                        component={Waveforms}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/journalarchive/patient/:patientId"
                                                        component={
                                                            JournalArchivePatient
                                                        }
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/journal/:journalId"
                                                        component={Journal}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/journal/:journalId/view-datafile/:datafileId"
                                                        component={
                                                            SegmentAnalysis
                                                        }
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/journalarchive"
                                                        component={
                                                            JournalArchive
                                                        }
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/patientregistry"
                                                        component={
                                                            PatientRegistry
                                                        }
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/editpatient/:patientId"
                                                        component={EditPatient}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/add-patient"
                                                        component={AddPatient}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/vicu/:vicuId/patients/"
                                                        component={ManageVICU}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/vicu"
                                                        component={AllVICU}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/devices/add-device"
                                                        component={AddDevice}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/devices/:deviceId/edit-device"
                                                        component={EditDevice}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/devices/:deviceId/"
                                                        component={
                                                            DeviceDetails
                                                        }
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/cohorts/add-cohort"
                                                        component={AddCohort}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/cohorts/:cohortId/"
                                                        component={Cohort}
                                                    />
                                                    <ProtectedRoute
                                                        exact
                                                        path="/cohorts/:cohortId/edit-cohort/"
                                                        component={EditCohort}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/staff"
                                                        component={Staff}
                                                    />




*/
