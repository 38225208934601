class IdleTimer {
    constructor({ timeout, onTimeout, onExpired }) {
      //console.log ("Created IdleTimer: ", timeout)
      this.timeout = timeout;
      this.onTimeout = onTimeout;
  
      //const targetTime = parseInt(localStorage.getItem("_targetTime"), 10);
      //console.log ("Constructor targetTime:", Date (targetTime))

//      if (targetTime > 0 && targetTime < Date.now()) {
//        console.log ("Expired in constructor --- !!!", targetTime)
//        onExpired();
//        return;
//      }
  
      this.eventHandler = this.updateTargetTime.bind(this);
      this.tracker();
      this.startInterval();
    } // constructor
  
    startInterval() {
      this.updateTargetTime();
  
      this.interval = setInterval(() => {
        const targetTime = parseInt(localStorage.getItem("_targetTime") ,10);

        if (targetTime < Date.now()) {
          if (this.onTimeout) {
            this.onTimeout();
            this.cleanUp();
          }
        }
      }, 1000);
    } // startInterval
  

    updateTargetTime() {
      if (this.timeoutTracker) {
        clearTimeout(this.timeoutTracker);
      }
      const datenow = Date.now();
      const dateTarget = datenow + this.timeout * 1000;
      this.timeoutTracker = setTimeout(() => {
        localStorage.setItem("_targetTime", dateTarget);
      }, 300);
    }
  

    tracker() {
      window.addEventListener("mousemove", this.eventHandler);
      window.addEventListener("scroll", this.eventHandler);
      window.addEventListener("keydown", this.eventHandler);
    }
  
    cleanUp() {
      localStorage.removeItem("_targetTime");
      clearInterval(this.interval);
      window.removeEventListener("mousemove", this.eventHandler);
      window.removeEventListener("scroll", this.eventHandler);
      window.removeEventListener("keydown", this.eventHandler);
    }
  }
  export default IdleTimer;
  