import { useState, useEffect } from "react";
import {
    useTheme,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import UserService from "../../services/UserService";

export const checkEmail = (str: string) => {
    return /^[a-zA-Z0-9._-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(str);
};

export const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const { t } = useTranslation();
    const theme = useTheme();
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        //console.log(checkEmail(email));
        if (checkEmail(email)) {
            setValidEmail(true);
        } else {
            if (email !== "") {
                setValidEmail(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const handleCloseSave = () => {
        console.log("Close dialog handler");
        setShowMessage(true);
        UserService.forgotPassword(email)
            .then((res: any) => {
                console.log("Result:", res);
                if (res.code === 200) {
                    console.log("Success! " + res.message);
                }
            })
            .catch((error: any) => {
                console.log("Error requesting token");
            })
            .finally(() => {
                console.log("Completed request for token ");
            });
    };

    const handleCloseOK = (
        event: React.SyntheticEvent<unknown>,
        reason?: string
    ) => {
        if (reason !== "backdropClick") {
            setShowMessage(false);
        }
    };

    return (
        <div>
            <Grid container>
                <Typography color={theme.palette.text.primary}>
                    {t("request_pwd_reset_msg")}
                </Typography>
            </Grid>
            <Grid container>
                {/* SUCCESS DIALOG */}
                <Dialog open={showMessage} fullWidth={true} maxWidth={"md"}>
                    <DialogTitle> {t("success")}</DialogTitle>

                    <DialogContent>
                        <Typography>{t("password_reset_sent")}</Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleCloseOK}> {t("close")}</Button>
                    </DialogActions>
                </Dialog>

                <Grid item xs={12} md={5} mt={2}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email_address"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        label={t("enter_email_address")}
                        type="email"
                        fullWidth
                        variant="outlined"
                        sx={{
                            input: {
                                color: theme.palette.text.primary,
                                background: theme.palette.background.default,
                            },
                            label: {
                                color: theme.palette.text.primary,
                            },
                        }}
                    />
                    {!validEmail && (
                        <Typography color={theme.palette.error.main}>
                            {t("invalid_email_address")}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Button onClick={handleCloseSave}>
                        {t("request_password_reset")}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}; // ForgotPassword
