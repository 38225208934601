import {
    Typography,
    Box,
} from "@mui/material";
import Slider from '@mui/material/Slider';
import { useTranslation } from "react-i18next";
import { useRef } from "react";

export interface GranularityProps {
    sliderWidth?: number;
    setParmInterval: (val:number) => void;
    handleChange?: (val:number) => void;
    title: string;
    defaultval?: number;
}


export const GranularitySelector = ({setParmInterval, handleChange, sliderWidth=500, defaultval=10, title} : GranularityProps) => {
    // console.log ("GranularitySelector: default = ", defaultval)
    const val2idx: {[key: number]: number}= {
        1:   1,
        10:  2,
        30:  3,
        60:  4,
        300: 5,
        600: 6
      }
    const { t } = useTranslation();
    const previous_setting = useRef (defaultval)
    const initialValue = useRef (val2idx[defaultval])

    const marks = [
        { value: 1, label: '1 '  + t("second")  },
        { value: 2, label: '10 ' + t("seconds") },
        { value: 3, label: '30 ' + t("seconds") },
        { value: 4, label: '1 ' + t("minute")   },
        { value: 5, label: '5 ' + t("minutes")  },
        { value: 6, label: '10 ' + t("minutes") }
      ];
      
      function valueLabelFormat(value: number) {
        return marks.findIndex((mark) => mark.value === value) + 1;
      }


      const handleSliderChange = (event: Event, newValue: number | number[]) => {
        var new_setting: number = 10;
        switch (newValue) {
            case 1: new_setting = 1; break;
            case 2: new_setting = 10; break;
            case 3: new_setting = 30; break;
            case 4: new_setting = 60; break;
            case 5: new_setting = 300; break;
            case 6: new_setting = 600; break;
        }
        setParmInterval (new_setting);

        if (handleChange) {
            if (new_setting !== previous_setting.current) {
                previous_setting.current = new_setting;
                handleChange (new_setting)
            }
        }

      } // handleSliderChange

    return (
        <div>

        <Typography ml={1}>{title}</Typography>
        <Box sx={{ width: sliderWidth, margin:3 }}>
            <Slider
                defaultValue={initialValue.current}
                valueLabelFormat={valueLabelFormat}
                step={null}
                min={1}
                max={6}
                marks={marks}
                valueLabelDisplay="off"
                track={false}
                onChange={handleSliderChange}
                />
            </Box>
            </div>

    )
 }