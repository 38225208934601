import { Loading } from "../components/Loading";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import useFetch from "../hooks/useFetch";
//import useFetchPages from "../hooks/useFetchPages";
import { ServerProblem } from "./ReRoute/ServerProblem";
import DeviceService from "../services/DeviceService";
import {CareSummaryInfo} from "../components/chart/CareSummaryInfo"
import {MAX_REPORT_DURATION_SEC} from "../components/chart/CareSummaryInfo"
import {memo} from "react";
import { useEffect, useState } from "react";




const CareSummaryInfoMemo = memo (CareSummaryInfo)
/*
 * The CareSummary component loads one-time data and passes it down to the CareSummaryInfo component which 
 * implements the page.
 */
export const CareSummary = () => {
    //console.log ("Render CareSummary")
    const params    = useParams();
    const [cookies] = useCookies(["access_token"]);
    // @ts-ignore
    const deviceId:string = params["deviceId"];
    const [parmHist, setParmHist] = useState ()
    const [loadingHistory, setLoadingHistory] = useState (true);
    //const { t } = useTranslation();



    // Get details on the device
    const { data: device, loading, error } = useFetch({
        url: DeviceService.getDevice(),
        access_token: cookies.access_token,
        // @ts-ignore 
        id: deviceId,
    });

    /*
     * This works with a single packet. The call below needs to be replaced with a multi-page retrieval.
     *
     */
    // Retrieve historical vital sign values
    // 48 hours = 48 * 60 * 60 = 172800
    // 6 hours = 6 * 60 * 60 = 21600
    //const max_returns = 500; // Page size

    // Set up first page retrieval. We retrieve at most "max_returns" data packets beginning from start_time up to end_time. 
    const now        = new Date ()
    //const end_time   = now.toISOString()
    const start      = now;
    start.setSeconds(start.getSeconds() - MAX_REPORT_DURATION_SEC);
    //const start_time = start.toISOString()

    //const { data: parmHistory, loading: loadingHistory, error:errorHistory } = useFetch({
    //    url: DeviceService.getLatestData (MAX_REPORT_DURATION_SEC, [], false),
    //    access_token: cookies.access_token,
    //    id: deviceId,
    //});
    //var loadingHistory = true;
    const errorHistory = false;
    useEffect (() => {
        const d = {
            "device_id" : deviceId,
            "duration"  : MAX_REPORT_DURATION_SEC
        }
        DeviceService.getTrendData (d, cookies.access_token).then ((res: any) => {
//            console.log ("this is the returned data:", res)
            setParmHist (res.data);
            setLoadingHistory (false);
        })
    
    }, [deviceId, cookies.access_token])
   

    // One-time on component load: retrieve alarm profiles from back-end
    const {
        data:    alarmProp,
        loading: almLoading,
    } = useFetch({
        url: DeviceService.getTypes (),
        access_token: cookies.access_token
    });
    
    if (loading || almLoading || loadingHistory || device === null) return <Loading />;
    if (!parmHist) return <Loading />;
    if (error|| errorHistory) { return <ServerProblem />; }

    //console.log ("CareSummary")

    //console.log ("parm history data:", parmHistory)
    //console.log ("CareSummary renders - parm hist data:", parmHist)

    const almProfileDef = JSON.parse (device.alarm_profile)
    
    return (
        <CareSummaryInfoMemo device={device} 
                         initialAlmProfileDef = {almProfileDef} 
                         parmHistory={parmHist} 
                         alarmProp={alarmProp}
        />
    );
};




