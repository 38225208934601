import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
    TextField,
    Grid,
    Typography,
} from "@mui/material";

import { Loading } from "../../components/Loading";
import { Device } from "../../interfaces/Device";
import { useState } from "react";
import { useTranslation } from "react-i18next";
//import { ServerProblem } from "../ReRoute/ServerProblem";
import { DeviceDialog } from "./Device/DeviceDialog";
import { isBrowser } from "react-device-detect";

interface Props {
    devicelist: Device[];
    alarmProp: any;
}

export const DeviceTable = ({ devicelist, alarmProp }: Props) => {
    const [device, setDevice] = useState<Device | null>(null);
    const [showDialog, setShowDialog] = useState(false);
    const [rows, setRows] = useState<Device []> (devicelist)
    const [searched, setSearched] = useState<string>("");
    const { t } = useTranslation();
    const theme = useTheme();
    const fontVariant = isBrowser ? "body2" : "caption";

    if (!devicelist || !alarmProp) {
        return <Loading />;
    }

    const requestSearch = (searchedVal: string) => {
        const filteredRows = devicelist.filter((row) => {
            const searchstring: string = row.device_id + row.device_type + row.device_model + row.location + row.nickname
          return searchstring.toLowerCase().includes(searchedVal.trim().toLowerCase());
        });
        setRows(filteredRows);
      };
    const doSearch = (event: any) => {
        setSearched (event.target.value)
        requestSearch (event.target.value)
    }
    return (
        <Grid container>
            <TextField id="outlined-basic" label={t("Search")} variant="outlined"
                      value={searched}
                      onChange={doSearch}
                      InputLabelProps={{ shrink: true }}
                      placeholder={t("type_to_search")}
                      sx={{
                        input: {
                            color: theme.palette.text.primary,
                            background:
                                theme.palette.background.default,
                        },
                        label: {
                            color: theme.palette.text.primary,
                        },
                        marginBottom: 1
                    }}
            />

            <TableContainer component={Paper}>
            <DeviceDialog
                openState={showDialog}
                setOpenState={setShowDialog}
                device={device}
                alarmProp={alarmProp}
            />

            <Table aria-label="simple table">
                <TableHead>
                    <TableRow
                        sx={{
                            border: 0,
                            bgcolor: theme.palette.primary.main,
                            "& th": {
                                color: theme.palette.text.secondary,
                            },
                        }}
                    >
                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("device_id")}
                        </TableCell>
                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("nickname")}
                        </TableCell>
                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("type")}
                        </TableCell>
                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("model")}
                        </TableCell>
                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("location")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((device: Device, i: number) => (
                        <TableRow
                            onClick={() => {
                                setDevice(device);
                                setShowDialog(true);
                            }}
                            key={i}
                            hover
                            sx={{
                                "&:nth-of-type(odd)": {
                                    background: theme.palette.info.dark,
                                },
                                "&:nth-of-type(even)": {
                                    background: theme.palette.info.light,
                                },
                                cursor: "pointer",
                            }}
                            >
                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {device.device_id}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {device.nickname}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {device.device_type}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {device.device_model}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {device.location}
                                </Typography>
                            </TableCell>
                        </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>

        </Grid>
    );
};
