import { LinearProgress, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Loading = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <>
            <Typography color={theme.palette.text.primary} variant="h3">
                {t("loading")}...
            </Typography>
            <LinearProgress sx={{ marginTop: "2rem" }} />
        </>
    );
};
