import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Alarm } from "../interfaces/Alarm";

interface AlarmsCtx {
    alarms: Alarm[] | null;
    setAlarms: Dispatch<SetStateAction<any | null>>;
}

export const AlarmsContext = createContext<AlarmsCtx | null>(null);

export default function AlarmsProvider({ children }: any) {
    const [alarms, setAlarms] = useState<any | null>(null);

    const alertContext = { alarms, setAlarms };

    return (
        <AlarmsContext.Provider value={alertContext}>
            {children}
        </AlarmsContext.Provider>
    );
}
