import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import OrgService from "../../services/OrganizationService";
import { useState } from "react";
import { OrgTable} from "./OrgTable";
import { OrgDialog } from "./OrgDialog"
import { User } from "../../interfaces/user/User";



export const SystemManagement = () => {
    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState ("edit");
	const [user] = useState<User | null>(
		// @ts-ignore
		JSON.parse(localStorage.getItem("user")) || null
	);

    // Fetch all Organizations
    const {
        data: orgList,
        loading: orgsLoading,
        error: orgsError,
    } = useFetch({
        url: OrgService.getAllOrganizations(),
        access_token: cookies.access_token,
    });

    if (orgsLoading) {
        return <Loading />;
    }
    if (orgsError) {
        return <ServerProblem />;
    }

    if (orgList === null) {
        return <>No Organizations have been defined</>;
    }

    const addDialog = () => {
        setDialogMode ("add");
        setShowDialog(true);
    };

    console.log(orgList, showDialog);
    return (
        <div>
                <OrgDialog
                    openState={showDialog}
                    setOpenState={setShowDialog}
                    targetOrg={undefined}
                    dialogMode = { "add" }
                />

            <Grid item>
                <OrgTable orglist={orgList} dialogMode = {dialogMode} />
            </Grid>

            { user?.is_superadmin && 
                <Grid item sx={{ mt: 2 }}>
                    <Button variant="contained" onClick={addDialog}>
                        {t("add_organization")}
                    </Button>
                </Grid>
            }
        </div>
    );
};
