import { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import useFetch from "../hooks/useFetch";
import "./Waveforms/Waveforms.css";
import { ServerProblem } from "./ReRoute/ServerProblem";
import DeviceService from "../services/DeviceService";
import { LiveUnit } from "./Home/Live/LiveUnit";
import DeviceMonitor from "../components/device/DeviceMonitor";
import SelectParameters from "../components/device/SelectParameters";
import { TrendUnit } from "./Home//Trend/TrendUnit";
import { isBrowser } from "react-device-detect";
import { parmMap } from "./Home/LiveDashboard";
import { useExternalScript } from "../hooks/useExternalScript";
import { EventTable } from "../components/EventTable"


export interface Event {
	timestamp: Date,
	eventType: string,
	description: string,
	eventData: string
  }
  

const openPDF = (base64String: string) => {
    // This will convert your base64 string into a byte array
    const binaryString = window.atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a new Blob object using the byte array and specify it as a PDF
    const file = new Blob([bytes.buffer], { type: 'application/pdf' });

    // Create a new object URL for the blob
    const fileURL = URL.createObjectURL(file);

    // Open the object URL in a new tab.
    window.open(fileURL, '_blank');
};

export const WaveMonitor = () => {
	const ALL_WAVEFORMS = [
		"ECG_L1",
		"ECG_L2",
		"ECG_L3",
		"ECG_V",
		"ECG_AVR",
		"ECG_AVL",
		"ECG_AVF",
		"SpO2",
		"Resp",
		"IBP1",
		"IBP2",
		"EtCO2",
		"ACT",
	];
	const ALL_PARAMETERS = [
		"HR",
		"SpO2",
		"IBP1",
		"IBP2",
		"NIBP",
		"Resp",
		"Temp",
	];
	const params = useParams();
	const [cookies] = useCookies(["access_token"]);
	const [selectableParameters] = useState<string[]>([]);
	const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";

	const addEvent = (event: Event) => {
		switch (event.eventType) {
			case "alarm" : {
				setAlarmList (prevAlarms => [event, ...prevAlarms]);
				break;
			}
			case "event" : {
				setEventList(prevEvents => {
					const updatedEvents = [event, ...prevEvents];
					updatedEvents.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
					return updatedEvents;
				  });
				

				console.log ("Event received:", event)
				break;
			}
			case "ecg" : {
				setEcgList (prevEcgs => [event, ...prevEcgs]);
				break;
			}
		} // switch
	  };
	
	  const [alarmList, setAlarmList] = useState<Event[]>([]);
	  const [eventList, setEventList] = useState<Event[]>([]);
	  const [ecgList, setEcgList] = useState<Event[]>([]);
  
	const handleEventSelected = (event: Event) => {
	  // Do something with the selected event
	  console.log(event);
	  switch (event.eventType) {
		case "ecg" : {
			const evData = JSON.parse (event.eventData);
			openPDF (evData["data"])
		}
	  }
	};
  
  
	// @ts-ignore
	const deviceId: string = params["deviceId"];
	const savedParams = JSON.parse(
		// @ts-ignore
		localStorage.getItem("wavemonitor-" + deviceId)
	);
	const [selectedParameters, setSelectedParameters] = useState<string[]>(
		savedParams || ALL_PARAMETERS
	);

	const {
		data: device,
		loading,
		error,
	} = useFetch({
		url: DeviceService.getDevice(),
		// @ts-ignore
		id: deviceId,
		access_token: cookies.access_token,
	});

	const state = useExternalScript("../ivideon/iv-standalone-web-sdk.js");

	useEffect(() => {
		if (state === "ready" && device) {
			// @ts-ignore
			_ivideon.sdk
				.init({
					rootUrl: "../../ivideon/",
					l10nOptions: {
						availableLanguages: ["de", "en", "fr"],
						language: "en",
					},
				})
				.then(
					function (sdk: any) {
						sdk.configureWithCloudApiAuthResponse({
							api_host: "openapi-alpha.ivideon.com",
							access_token: device.camera_token,
						});

						var camera = sdk.createCamera({
							id: device?.camera_id,
							cameraName: "Pet Cam",
							imageWidth: 800,
							imageHeight: "123px",
							soundEnabled: false,
						});

						var player = sdk.createPlayer({
							container: "#ivideon_stream",
							camera: camera,
						        sizeCalculationMethod : sdk.playerSize.FILL_BY_WIDTH,
							"timelineEnabled" : false,
							"playPauseButtonEnabled" : false,
							"goToLiveButtonEnabled" : false,
							"volumeControlEnabled" : false,
							"qualityControlEnabled" : false,
							"speedControlEnabled" : false,
							"previewEnabled" : false,
							"previewFromCameraEnabled" : false,
						        layout: "expanded"
						});

						player.playLive({ quality: 2 });
					},
					function (error: any) {
						console.error(error);
					}
				)
				.catch((error: any) => {
					console.error(error);
				});
		}
	}, [state, device]);

	if (loading || device === null) return <Loading />;

	if (error) {
		return <ServerProblem />;
	}


	const availableParameters = parmMap[device.device_model].parms;
	return (
		<Grid container spacing={2} style={{ height: '100vh' }}>
			<Grid item xs={8} style={{display: 'flex', flexDirection: 'column'}}>

				<Box sx={{ height: isBrowser ? 450 : 225 }}>
					<LiveUnit
						key={deviceId}
						device={device}
						width={12}
						type="livewaveforms"
						availableParameters={availableParameters}
						availableWaveforms={ALL_WAVEFORMS}
						header={true}
						minHeight={isBrowser ? 400 : 150}
						parameterBox={false}
						useLink={false}
						showWaveform={true}
						hideCamera={true}
						addEvent={addEvent}
					/>
				</Box>
				<Box>
					<TrendUnit
						key={"trend_" + device.device_id}
						device={device}
						hideTrend={false}
						type="wavemonitor-trend"
						useLink={false}
						connectStatus="undefined"
						fullWidth={true}
					/>

				</Box>
			</Grid>

			<Grid item xs={4} style={{ display: "flex", flexDirection: "column" }}>
				<Box>
					<DeviceMonitor
						device={device}
						fullWidth={true}
						type={"wavemonitor"}
						useLink={false}
						availableParams={availableParameters}
						selectParams={
							<SelectParameters
								deviceId={device.device_id}
								selectableParameters={selectableParameters}
								selectedParameters={selectedParameters}
								setSelectedParameters={setSelectedParameters}
								maxParameters={selectableParameters.length}
								type="wavemonitor"
							/>
						}
					/>
				</Box>

				
				 <Box style={{flexGrow: 1, height:80, overflowY: 'auto'}}>
				 { !isPharlap && <EventTable
						alarmList={alarmList}
						eventList={eventList}
						ecgList={ecgList}
						onEventSelected={handleEventSelected}
						style={{ flex: 1, overflowY: "auto" }}
					/>
				}
	
				{device && state === "ready" && (
					<div
						className="myapp-player-container"
						id="ivideon_stream"
						style={{
							width: "100%",
							height: "123px",
							marginLeft: 4,
							marginRight: 4,
							marginTop: isBrowser ? 0 : 40,
							marginBottom: isBrowser ? 0 : 120,
						}}
					></div>
				)}
				</Box>
			</Grid>
		</Grid>
	);
	
	
	
};
