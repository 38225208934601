import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Device } from "../../../interfaces/Device";
import { NEWSRow } from "./NEWSRow";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { ReactNode, useEffect } from "react";
import { Typography } from "@mui/material";
import { isBrowser } from "react-device-detect";

interface Props {
    devicelist: Device[];
    children: ReactNode;
}

export const NEWSTable = ({ devicelist, children }: Props) => {
    //console.log ("NEWSTable renders", devicelist)
    const { t } = useTranslation();
    const isMounted = useIsMounted();
    const [sortedDevices, setSortedDevices] = useState<Device[]>(devicelist);
    const [refresh, setRefresh] = useState<boolean>(false);
    const fontVariant = isBrowser ? "body2" : "caption";

    const setNEWS = (device_id: string, score: number) => {
        // Look up the device using the device id

        if (!isMounted()) {
            return;
        }
        for (var i = 0; i < sortedDevices.length; i++) {
            if (sortedDevices[i].device_id === device_id) {
                sortedDevices[i].NEWS_score = score;
            }
        }

        // Sort the array again on the new NEWS values
        const tmp_list = sortedDevices.sort((n1, n2) => {
            const s1 = n1.NEWS_score ? n1.NEWS_score : 0;
            const s2 = n2.NEWS_score ? n2.NEWS_score : 0;

            if (s1 > s2) {
                return -1;
            }

            if (s1 < s2) {
                return 1;
            }

            return 0;
        });
        setSortedDevices(tmp_list);
        setRefresh(!refresh);
    }; // setNEWS

    useEffect(() => {
        setSortedDevices(devicelist);
    }, [devicelist]);
    //console.log ("NEWSTable xx renders", sortedDevices)
    return (
        <>
            {children}
            {sortedDevices?.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {isBrowser && (
                                    <>
                                        <TableCell size="small">
                                            {t("patient")}
                                        </TableCell>
                                        <TableCell>Monitor&nbsp;ID</TableCell>
                                        <TableCell>Status</TableCell>
                                    </>
                                )}
                                <TableCell
                                    size="small"
                                    sx={{
                                        padding: "6px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        Pulse {isBrowser && t("beats_min")}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    size="small"
                                    sx={{
                                        padding: "6px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        SpO2 {isBrowser && "(%)"}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    size="small"
                                    sx={{
                                        padding: "6px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        Temp {isBrowser && "(°C | °F)"}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    size="small"
                                    sx={{
                                        padding: "6px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        Systolic BP {isBrowser && "(mmHg)"}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    size="small"
                                    sx={{
                                        padding: "6px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        RR {isBrowser && t("breath_min")}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    size="small"
                                    sx={{
                                        padding: "6px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        {t("news_score")}
                                    </Typography>
                                </TableCell>
                                {isBrowser && (
                                    <TableCell sx={{ minWidth: "125px" }}>
                                        <Typography
                                            variant={fontVariant}
                                            fontWeight="500"
                                            sx={{
                                                padding: "6px 6px",
                                            }}
                                        >
                                            {t("news_details")}
                                        </Typography>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {sortedDevices?.map((device: Device) => (
                                <NEWSRow
                                    key={device.device_id}
                                    device={device}
                                    setNEWS={setNEWS}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No devices to show</Typography>
            )}
        </>
    );
};
